<template>
  <el-form :model="contractForm" :rules="rules" ref="form" label-width="100px" class="contractForm" size="medial">
    <el-row>
      <el-col :span="12">
        <el-form-item label="合同编号" prop="contract_id">
          <el-input v-model="contractForm.contract_id"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="甲方单位" prop="first_party">
          <el-select v-model="contractForm.first_party" placeholder="请选择甲方单位" style="width:200px">
            <el-option v-for="item in first_party_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-form-item label="施工地点" prop="project">
          <el-input v-model="contractForm.project"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="合同金额" prop="contract_amount">
          <el-input type="number" step="0.01" v-model="contractForm.contract_amount"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="乙方单位" prop="first_party">
          <el-input v-model="contractForm.second_party"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="乙方签约人" prop="contact">
          <el-input v-model="contractForm.contact"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row> </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="签约时间" prop="signing_date">
          <el-date-picker
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            v-model="contractForm.signing_date"
            style="width: 100%;"
          ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="施工材料" prop="construction_material">
          <el-input type="textarea" :rows="4" v-model="contractForm.construction_material"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  props: {
    contractForm: Object
  },
  data() {
    return {
      isValidationOk: false,
      collection_person_options: [],
      contractor_person_options: [],
      first_party_options: [],
      contract_name_options: ['钢板桩租赁合同', '钢板桩施工合同'],
      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        contract_id: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
        contract_name: [{ required: true, message: '请输入合同名称', trigger: 'blur' }],
        signing_date: [{ required: true, message: '请选择签约时间', trigger: 'blur' }],
        contractor: [{ required: true, message: '请输入签约人', trigger: 'blur' }],
        first_party: [{ required: true, message: '请输入甲方单位', trigger: 'blur' }],
        project: [{ required: true, message: '请输入名称及地点', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contact_phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        contract_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }],
        // real_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }],
        collection_person: [{ required: true, message: '请选择回款负责人', trigger: 'blur' }],
        construction_material: [{ required: true, message: '请填写施工材料', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getCollectionPersons() {
      this.$axios.get('/getuserlist/').then(res => {
        console.log('users', res.data)
        res.data.forEach(element => {
          let user = {}
          user.label = element.name
          user.value = element.id
          this.collection_person_options.push(user)
        })
        console.log('collection_person_options', this.collection_person_options)
      })
    },
    getContractorPersons() {
      this.$axios.get('/getuserlist/').then(res => {
        console.log('users', res.data)
        res.data.forEach(element => {
          let user = {}
          user.label = element.name
          user.value = element.name
          this.contractor_person_options.push(user)
        })
        console.log('contractor_person_options', this.contractor_person_options)
      })
    },
    getFirstPartyList() {
      this.$axios.get('/getFirstPartyList/').then(res => {
        console.log('users', res.data)
        res.data.forEach(element => {
          let first_party = {}
          first_party.label = element.name
          first_party.value = element.name
          this.first_party_options.push(first_party)
        })
        console.log('first_party_options', this.first_party_options)
      })
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    }
  },
  created() {
    this.getCollectionPersons()
    this.getFirstPartyList()
    this.getContractorPersons()
    console.log('session:', sessionStorage.getItem('test'))
  }
}
</script>

<style lang="scss" scoped></style>
